$(document).ready(function(){
    function formValidation(field) {
        console.log($(field)[0].type === "email");
        $(field).removeAttr("class");
        if(field.required === true) {
            if (($(field).val().length === 0) || $(field).val() === " " || $(field).val()[0] === " " || ($(field)[0].type === "email" && ($(field).val().indexOf("@") === -1 || $(field).val().indexOf(".") === -1 || $(field).val().length < 5))) {
                $(field).addClass("invalid");
            }else{
                $(field).addClass("valid");
            }
        }else{
            $(field).removeAttr("class");
            if($(field).val().length > 0) {
                $(field).addClass("valid");
            }
        }

    }
    $("body").addClass("js");
    var allowScrolling = true;
    // MENU
    $("a.menu").on("click", function(e) {
        e.preventDefault();
        $("nav ul").slideToggle();
        $("div.shadowBox").fadeToggle();
    });
    // TEASER SCROLL
    if($("aside#teaser").length > 0) {
        $(window).on("mousewheel keydown", function (e) {
            if(window.innerWidth >= 760 && allowScrolling === true) {
                if(window.pageYOffset === 0 && e.originalEvent.wheelDelta < 0) {
                    allowScrolling = false;
                    $('html, body').animate({
                        scrollTop: $("nav").offset().top
                    }, 1000, function() {
                        $("aside#teaser").remove();
                    });
                }
            }
        });
        $("a#scrollDown").on("click tap", function(e) {
            if(window.innerWidth >= 760 && allowScrolling === true) {
                console.log("test1");
                if(window.pageYOffset === 0) {
                    console.log("test2");
                    allowScrolling = false;
                    $('html, body').animate({
                        scrollTop: $("nav").offset().top
                    }, 1000, function() {
                        $("aside#teaser").remove();
                    });
                }
            }
        });
    }
    // CONTACT
    if($("form#contactForm").length > 0) {
        $("input:not([type=submit]), textarea").on("keyup", function () {
            formValidation(this);
        });
       /*$("form#contactForm").on("submit", function(e) {
           e.preventDefault();
           console.log($(".invalid"));
           if($(".invalid").length == 0) {
               $("form#contactForm").submit();
           }
        });*/
        $('input[type=radio][name=type]').on("change", function() {
            if(this.id === "question") {
                $("li.subject").slideDown();
            }else{
                $("li.subject").slideUp();
            }
            if(this.id === "onderhoud") {
                $("label[for=address] span").fadeOut();
                $("input#address").attr("required","");
                $("select#brand").attr("required","");
                $("li.brand").slideDown();
            }else{
                $("label[for=address] span").fadeIn();
                $("input#address").removeAttr("required");
                $("select#brand").removeAttr("required");
                $("li.brand").slideUp();
            }
            $("input:not([required]):empty.invalid").removeClass("invalid");
        });
        if($("input[name=subject]").length > 0) {
            function placeholderUpdate() {
                var placeholders = [
                    "installatie badkamer",
                    "renovatie verwarming",
                    "onderhoud gasinstallatie",
                    "installatie nieuwe verwarmingsketel",
                    "reparatie centrale verwarming",
                    "klein onderhoud"
                ];
                var random = Math.ceil(Math.random() * placeholders.length);
                var subject = document.querySelector("input[name=subject]");
                if (placeholders[random - 1] !== undefined) {
                    subject.setAttribute("placeholder", "bv: " + placeholders[random - 1]);
                }
            }
            placeholderUpdate();
            setInterval(function () {
                placeholderUpdate();
            }, 5000);
        }
    }

    // Add select2 to the select field
    $('.select2').select2();

});